<template>
  <div>
    <span class="hidden">{{ computeSectionList }}</span>
    <div
      class="fixed delete_bha_popup overflow-y-auto top-0 w-full left-0 hidden model_height model_bg"
      :id="'td-delete'"
    >
      <div class="fixed inset-0 transition-opacity">
        <div class="absolute inset-0 bg-gray-900 opacity-75" />
      </div>
      <div class="container mx-auto px-4 h-full">
        <div class="flex content-center items-center justify-center h-full">
          <div class="w-full lg:w-4/12 modal_width_sidebar px-4">
            <div
              class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg_model border-0"
            >
              <div class="rounded-t mb-0 px-6 py-6">
                <div class="text-center mb-3">
                  <img :src="Logo" alt="" class="login_logo" />
                  <h6 class="text-white">DELETE {{ this.toBeDelete?.name }}</h6>
                </div>
              </div>
              <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
                <div class="text-center mt-6 flex btns_modal_p">
                  <button
                    type="button"
                    class="px-4 bg-gray-500 text-sm bg_cancel bg_modal_btns_sidebar rounded hover:bg-gray-700 text-sm font-bold button uppercase px-6 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150 py-1"
                    @click="toggleModalDelete()"
                  >
                    <i class="fas fa-times"></i> Cancel
                  </button>
                  <button
                    type="button"
                    class="px-4 bg-gray-500 text-sm bg_cancel bg_modal_btns_sidebar rounded hover:bg-gray-700 text-sm font-bold button uppercase px-6 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150 py-1"
                    @click="onDelete()"
                  >
                    <i class="fas fa-trash"></i> Delete
                  </button>
                </div>
                <div class="text-red-500">
                  {{ errcode }}
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="left_z_index h-full"
      :class="
        toggleSidebarProp
          ? 'side_pan_width'
          : 'side_pan_no_width' || darkDark !== 'white'
          ? 'dark-mode'
          : 'left_bg'
      "
      :style="[
        sideBarForProp == false ? { width: 0 + 'rem' } : { width: 10 + 'rem' },
      ]"
    >
      <nav
        v-if="sideBarForProp"
        class="close-sidebar-16rem z_index_20 md:left-0 md:block md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl flex flex-wrap items-center justify-between z-50 w-100"
        style="height: 100%;"
        id="mainNavBar"
        v-bind:style="{ left: toggleSidebarProp + 'rem' }"
      >
        <!--    :class="
            [darkMode == true ? 'dark-mode' : 'left_bg']" -->
        <div
          class="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto mx_height_side"
        >
          <!-- Toggler -->
          <button
            class="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
            type="button"
            v-on:click="toggleCollapseShow('bg-white m-2 py-3 px-6')"
          >
            <i class="fas fa-bars"></i>
          </button>

          <div
            class="md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded mt-0"
            v-bind:class="collapseShow" :style="{
      background: darkDark !== 'white' ? 'var(--sidebarbg)' : 'var(--sidebarbg)'}" >
            <div class="py-0 flex mneminics_mt" style="paddingleft: 10px">
              <h6
                class="px-2 py-0 drop_btn font-thin text-center text-white sidebar_heading"
                style="width: 100%; display: flex"
              >
                T & D {{ togglValue }}
              </h6>
            </div>

            <div v-if="showInsideData == false" class="left_lists_">
              <!-- sidebar option 1 -->

              <!-- T&D Side menu start here -->

              <ul v-if="true" class="left_mnemo h-full" :class="[darkDark !== 'white'  ? 'drop_backg' : '']">
                <li class="bg_right_">
                  <button
                    v-on:click="toggle_height_handler"
                    :class="[
                      'text-left',
                      'w-full',
                      'rounded',
                      'td_btn',
                      'flex justify-between',
                      darkDark !== 'white'  ? 'td_btn_dark' : 'drop_btn_light',
                    ]"
                  >
                    Well Section
                    <span v-if="!isExpandWellSection"
                      ><i class="fas fa-angle-down"></i
                    ></span>
                    <span v-else><i class="fas fa-angle-up"></i></span>
                  </button>
                  <ul
                    v-if="isExpandWellSection"
                    v-bind:style="{
                      height: isExpandWellSection ? '' : '0px',
                    }"
                    class="text-xs px-2"
                    :class="[darkDark !== 'white'  ? 'drop_backg' : '']"
                  >
                    <li
                      v-for="(section, index) in computeSectionList"
                      :key="index"
                    >
                      <label
                        :for="'radio_sec' + index"
                        :class="[
                          'text-left',
                          'w-full',
                          'rounded',
                          'td_btn',
                          'flex justify-between',
                        ]"
                        v-on:click="selectWellSection(section.wellsection_id)"
                      >
                        <div class="flex w-full justify-between">
                          {{ section.wellsection_id }}
                          <input
                            type="radio"
                            :id="'radio_sec' + index"
                            name="rt"
                            :checked="
                              selectedWellInfo?.wellsection_id ==
                              section.wellsection_id || (selected_well_section && selected_well_section == section.wellsection_id)
                            "
                          />
                        </div>
                      </label>
                    </li>
                  </ul>
                </li>

                <!-- Assembly Starts here -->

                <li class="bg_right_">
                  <button
                    v-on:click="toggle_height_handler_assembly"
                    :class="[
                      'text-left',
                      'w-full',
                      'rounded',
                      'td_btn',
                      'flex justify-between',
                      darkDark !== 'white'  ? 'td_btn_dark' : 'drop_btn_light',
                    ]"
                  >
                    Assembly
                    <span v-if="!isExpandAssemblySection"
                      ><i class="fas fa-angle-down"></i
                    ></span>
                    <span v-else><i class="fas fa-angle-up"></i></span>
                  </button>

                  <ul
                    v-if="isExpandAssemblySection"
                    v-bind:style="{
                      height: isExpandAssemblySection ? '' : '0px',
                    }"
                    class="text-xs px-2"
                    :class="[darkDark !== 'white'  ? 'drop_backg' : '']"
                  >
                    <li v-for="(assembly, index) in assemblyList" :key="index">
                      <label
                        :for="'radio_assbl' + index"
                        :class="[
                          'text-left',
                          'w-full',
                          'rounded',
                          'td_btn',
                          'flex justify-between',
                          'mt-2',
                        ]"
                        v-on:click="selectWellAssebly(assembly)"
                      >
                        <div class="flex w-full justify-between">
                          {{ assembly.bha_id }}
                          <input
                            type="radio"
                            :id="'radio_assbl' + index"
                            name="assembly"
                            :checked="
                              selectedWellInfo?.bha_id == assembly.bha_id && assemblyClicked
                            "
                          />
                        </div>
                      </label>
                    </li>
                  </ul>
                </li>

                <!-- BHA RUN starts -->
                <li class="bg_right_">
                  <button
                    v-on:click="toggle_height_handler_bha"
                    :class="[
                      'text-left',
                      'w-full',
                      'rounded',
                      'td_btn',
                      'flex',
                      darkDark !== 'white'  ? 'td_btn_dark' : 'drop_btn_light',
                    ]"
                  >
                    BHA Run
                    <span v-if="!isExpandBhaSection"
                      ><i class="fas fa-angle-down"></i
                    ></span>
                    <span v-else><i class="fas fa-angle-up"></i></span>
                  </button>

                  <ul
                    v-if="isExpandBhaSection"
                    v-bind:style="{
                      height: isExpandBhaSection ? '' : '0px',
                    }"
                    class="text-xs px-2"
                    :class="[darkDark !== 'white'  ? 'drop_backg' : '']"
                  >
                    <li
                      @contextmenu="this.bhaRunContextMenu($event, run)"
                      v-for="(run, index) in bhaRuns"
                      :key="index"
                      class="tooltip__tip top_ text-left selectedBtn_tooltip"
                      :data-tip="
                        new Date(run.start_time).toLocaleString('en-US', {
                          timeZone: 'CST6CDT',
                        }) +
                        '\n' +
                        (run.end_time == '1970-01-01T00:00:00.000Z'
                          ? ''
                          : new Date(run.end_time).toLocaleString('en-US', {
                              timeZone: 'CST6CDT',
                            }))
                      "
                    >
                      <label
                        :for="'radio_bha' + index"
                        :class="[
                          'text-left',
                          'w-full',
                          'rounded',
                          'td_btn',
                          'flex',
                          'mt-2',
                        ]"
                        v-on:click="selectBHARun($event, run)"
                      >
                        <div class="flex w-full">
                          {{
                            run.name
                          }}
                          <input
                            type="checkbox"
                            :id="'radio_bha' + index"
                            name="bha"
                            :checked="selectedSavedRun?.name == run.name"
                          />
                        </div>
                      </label>
                    </li>
                  </ul>
                </li>

                <!-- Torque & hookload switch -->
                <li>
                  <div class="td_toggle px-1">
                    <label for="td_checkbox">
                      <h6 class="td_text-xs">
                        <span :class="{ active_toggle_text: !tdcheckbox }"
                          >TORQUE</span
                        >
                        <span v-if="tdcheckbox" class="arrow_s">
                          <i class="fas fa-angle-right mx-0.5"></i>
                        </span>
                        <span v-else class="arrow_s">
                          <i class="fas fa-angle-left mx-0.5"></i>
                        </span>
                        <span :class="{ active_toggle_text: tdcheckbox }">
                          HKL
                        </span>
                      </h6>
                      <!-- <h6 class="td_text-xs" >
                            TORQUE
                            <span><i class="fas fa-angle-left"></i></span> HKL
                          </h6> -->
                    </label>

                    <input
                      type="checkbox"
                      class="checkbox hidden"
                      id="td_checkbox"
                      @click="toggleTdCheckbox"
                      value=""
                      :checked="tdcheckbox"
                    />
                    <label
                      for="td_checkbox"
                      class="checkbox-label td_checkbox"
                      :class="[
                        darkDark !== 'white'  ? 'td_btn_dark' : 'drop_btn_light',
                      ]"
                    >
                      <span class="ball"></span>
                    </label>
                  </div>
                </li>

                <li>
                  <label
                    for="rt"
                    :class="['text-left', 'rounded', 'td_btn', 'flex', 'mt-2']"
                  >
                    <div class="flex w-full">
                      RT DATA POINTS
                      <!-- <input v-on:click="rtCheckbox" type="checkbox" id="rt" name="rt" :checked="isRealtime" /> -->
                      <input
                        type="checkbox"
                        class="checkbox hidden"
                        id="rt"
                        @click="rtCheckbox"
                        :checked="isRealtime"
                      />
                      <label
                        for="rt"
                        class="checkbox-label td_checkbox_zoom"
                        style="margin-left: auto"
                      >
                        <span class="ball"></span>
                      </label>
                    </div>
                  </label>
                </li>
                <li>
                  <label
                    for="wellFormation"
                    :class="['text-left', 'rounded', 'td_btn', 'flex', 'mt-2']"
                  >
                    <div class="flex w-full">
                      FM
                      <!-- <input v-on:click="fmChange" type="checkbox" id="wellFormation" name="wellFormation" checked /> -->
                      <input
                        type="checkbox"
                        class="checkbox hidden"
                        id="wellFormation"
                        @click="fmChange"
                        checked
                      />
                      <label
                        for="wellFormation"
                        class="checkbox-label td_checkbox_zoom"
                        style="margin-left: auto"
                      >
                        <span class="ball"></span>
                      </label>
                    </div>
                  </label>
                </li>
                <li>
                  <label
                    for="rtVsPlan"
                    :class="['text-left', 'rounded', 'td_btn', 'flex', 'mt-2']"
                  >
                    <div class="flex w-full">
                      PLAN VS RT
                      <!-- <input v-on:click="rtVsPlan" type="checkbox" id="rtVsPlan" name="rtVsPlan"
                          :checked="planvsreal" /> -->
                      <input
                        type="checkbox"
                        class="checkbox hidden"
                        id="rtVsPlan"
                        @click="toggleRtVsPlanCheckbox"
                        value=""
                        :checked="rtVsPlan"
                      />
                      <label
                        for="rtVsPlan"
                        class="checkbox-label td_checkbox_zoom"
                        style="margin-left: auto"
                      >
                        <span class="ball"></span>
                      </label>
                    </div>
                  </label>
                </li>
                <!-- RT VS PLANNED -->
                <li>
                  <div class="td_toggle px-1">
                    <label for="rt_vs_plan_checkbox">
                      <h6 class="td_text-xs">
                        <span :class="{ active_toggle_text: !planvsreal }"
                          >PLAN</span
                        >
                        <span v-if="planvsreal" class="arrow_s">
                          <i class="fas fa-angle-right mx-0.5"></i>
                        </span>
                        <span v-else class="arrow_s">
                          <i class="fas fa-angle-left mx-0.5"></i>
                        </span>
                        <span :class="{ active_toggle_text: planvsreal }">
                          RT
                        </span>
                      </h6>
                      <!-- <h6 class="td_text-xs" >
                            TORQUE
                            <span><i class="fas fa-angle-left"></i></span> HKL
                          </h6> -->
                    </label>

                    <input
                      type="checkbox"
                      class="checkbox hidden"
                      id="rt_vs_plan_checkbox"
                      @click="planvsrt"
                      :checked="planvsreal"
                    />
                    <label
                      for="rt_vs_plan_checkbox"
                      class="checkbox-label td_checkbox"
                      :class="[
                        darkDark !== 'white'  ? 'td_btn_dark' : 'drop_btn_light',
                      ]"
                    >
                      <span class="ball"></span>
                    </label>
                  </div>
                </li>
                <!-- Zoom Switch -->
                <li>
                  <label
                    for="td_zoomOnOff"
                    :class="['text-left', 'rounded', 'td_btn', 'flex', 'mt-2']"
                  >
                    <div class="flex w-full">
                      <div v-if="zoom_checked == true">ZOOM ON</div>
                      <div v-if="zoom_checked == false">ZOOM OFF</div>
                      <input
                        type="checkbox"
                        class="checkbox hidden"
                        id="td_zoomOnOff"
                        @click="zoom_toggle"
                        :checked="zoom_checked"
                      />
                      <label
                        for="td_zoomOnOff"
                        class="checkbox-label td_checkbox_zoom"
                        style="margin-left: auto"
                      >
                        <span class="ball"></span>
                      </label>
                    </div>
                  </label>
                  <!-- <div class="text-xs">
                        zoom
                      </div> -->
                </li>

                <!-- T&D -->
              </ul>
              <!-- T&D Side menu Ends here -->
            </div>
          </div>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
// import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
// import { ChevronRightIcon } from "@heroicons/vue/solid";
// import { ChevronLeftIcon } from "@heroicons/vue/solid";
import move from "../../assets/img/move.png";
import api from "../../api/services";
let flag = true;
export default {
  data() {
    return {
      tdcheckbox: this.hklOrTor,
      rtVsPlan: false,
      assemblyList: [],
      wellSectionsFilter: [],
      collapseShow: "hidden",
      toggleSidebar: "",
      toggleSideDbbar: this.toggleSidebarProp,
      showInsideData: false,
      openTab: 1,
      openService: 1,
      isOpen: true,
      search: "",
      value: 10,
      max: 0,
      orientation: "vertical",
      direction: "rtl",
      move,
      tags: [],
      displayTags: [],
      units: [],
      logId: null,
      wellboreState: null,

      isDraggable: false,
      dashboards: [],
      screens: [],
      backgrounds: [],
      savedData: [],
      savedId: "",
      savedName: "",
      savedDbId: "",
      savedDbName: "",
      activeFor: "",
      activeIndexDB: -1,
      activeIndexSrn: -1,
      activeIndexBg: -1,
      isHovering: false,
      hoverIndex: -1,
      description: [],
      isExpandWellSection: true,
      isExpandAssemblySection: false,
      isExpandBhaSection: false,
      key: "",
      wellSections: [],
      togglValue: "TORQUE",
      selectedWellId: this.wellId,
      selectedWellBoreId: this.wellboreId,
      //  darkMode: false,
      //    minAngle: 10,
      // maxAngle: 30
      bhaRuns: [],
      zoom_checked: this.isZoomFeature,
      toBeDelete: "",
      wellsectChecked: false,
      assemblyClicked: false,
      selected_well_section: null,
    };
  },
  props: {
    planvsreal: Boolean,
    isRealtime: Boolean,
    toggleSidebarProp: String,
    sideBarForProp: String,
    savedList: Array,
    assemblySelected: Function,
    changeWellSelectHandler: Function,
    wellId: String,
    wellboreId: String,
    torHklToggle: Function,
    rtVsPlanToggle: Function,
    rtToggle: Function,
    isSaved: Boolean,
    listCount: Function,
    bhaSelected: Function,
    fmChange: Function,
    planvsrt: Function,
    zoomOnOff: Function,
    planId: String,
    bhaId: String,
    bhaName: String,
    selectedWellInfo: Object,
    hklOrTor: Boolean,
    selectedSavedRun: Object,
    isZoomFeature: Boolean,
  },
  computed: {
    tags() {
      // this.activeDisplay = this.$store.state.data.selectedDisplay;
      let display = this.$store.state.disp.selectedDisplay;

      let displayobj = this.$store.state.disp.displays;
      //
      if (displayobj[display]) {
        let displayTags = displayobj[display].displayTags;
        this.tags = displayobj[display].tags;

        this.units = displayobj[display].units;
        this.displayTags = displayTags;
        this.logId = displayobj[display].logId;
        this.state = displayobj[display].wellboreState;
        this.description = displayobj[display].descriptions
          ? displayobj[display].descriptions
          : [];

        return displayTags;
      } else {
        return [];
      }

      // return displayobj.tags;
    },

    computeSectionList() {
      return this.wellSectionsFilter.sort((a, b) => Number(b.wellsection_id) - Number(a.wellsection_id));
    },
    isDraggableWidgets() {
      let display = this.$store.state.disp.selectedDisplay;
      let displayobj = {};
      for (let i = 0; i < this.$store.state.disp.rects.length; i++) {
        if (display == this.$store.state.disp.rects[i].displayId)
          displayobj = this.$store.state.disp.rects[i];
      }
      if (displayobj && displayobj.isDraggableWidget) {
        this.isDraggable = true;
        return displayobj.isDraggableWidget;
      } else {
        this.isDraggable = false;
        return false;
      }
    },
    // sliderMin: {
    //   get: function() {
    //     var val = parseInt(this.minAngle);
    //     return val;
    //   },
    //   set: function(val) {
    //     val = parseInt(val);
    //     if (val > this.maxAngle) {
    //       this.maxAngle = val;
    //     }
    //     this.minAngle = val;
    //   }
    // },
    // sliderMax: {
    //   get: function() {
    //     var val = parseInt(this.maxAngle);
    //     return val;
    //   },
    //   set: function(val) {
    //     val = parseInt(val);
    //     if (val < this.minAngle) {
    //       this.minAngle = val;
    //     }
    //     this.maxAngle = val;
    //   }
    // }
    darkDark() {
      return this.$store.state.rect.darkmode;
    },
  },
  async mounted() {
    this.$store.subscribe(async (mutation, state) => {
      switch (mutation.type) {
        case "disp/setDisplay":
          this.tdcheckbox = this.hklOrTor;
          this.selected_well_section = null;
          this.assemblyList = [];
          this.wellSectionsFilter = [];
          this.collapseShow = "hidden";
          this.toggleSidebar = "";
          this.toggleSideDbbar = this.toggleSidebarProp;
          this.showInsideData = false;
          this.openTab = 1;
          this.openService = 1;
          this.isOpen = true;
          this.search = "";
          this.value = 10;
          this.max = 0;
          this.orientation = "vertical";
          this.direction = "rtl";
          this.tags = [];
          this.displayTags = [];
          this.units = [];
          this.logId = null;
          this.wellboreState = null;

          this.isDraggable = false;
          this.dashboards = [];
          this.screens = [];
          this.backgrounds = [];
          this.savedData = [];
          this.savedId = "";
          this.savedName = "";
          this.savedDbId = "";
          this.savedDbName = "";
          this.activeFor = "";
          this.activeIndexDB = -1;
          this.activeIndexSrn = -1;
          this.activeIndexBg = -1;
          this.isHovering = false;
          this.hoverIndex = -1;
          this.description = [];
          this.isExpandWellSection = true;
          this.isExpandAssemblySection = false;
          this.isExpandBhaSection = false;
          this.key = "";
          this.wellSections = [];
          this.togglValue = "TORQUE";
          this.selectedWellId = this.wellId;
          this.selectedWellBoreId = this.wellboreId;
          this.bhaRuns = [];
          this.zoom_checked = this.isZoomFeature;
          this.toBeDelete = "";
          this.wellsectChecked = false;
          break;
      }
    });
  },
  methods: {
    toggleModalDelete(e) {
      document.getElementById("td-delete").classList.toggle("hidden");
    },
    bhaRunContextMenu(e, run) {
      e.stopPropagation();
      e.preventDefault();

      this.toBeDelete = run;
      this.toggleModalDelete();
    },
    async getWellSections() {
      this.selected_well_section = null;
      this.assemblyList = [];
      let response = await api.WellServices.getTorqueAndDragWellInfo({
        customer: this.$store.state.data.customer,
        wellId: this.selectedWellId,
        wellboreId: this.selectedWellBoreId,
      });
      this.wellSections = response.data.rows;
      let wellsect = [];
      let wellSectionsFilter = this.wellSections.filter((d) => {
        if (wellsect.indexOf(d.wellsection_id) == -1) {
          wellsect.push(d.wellsection_id);
          return true;
        } else {
          return false;
        }
      });
      this.wellSectionsFilter = wellSectionsFilter;

      if (this.selectedWellInfo) {
        for (let sec of wellSectionsFilter) {
          if (this.selectedWellInfo?.wellsection_id == sec.wellsection_id) {
            let assembly = this.wellSections.filter((d) => {
              this.wellsectChecked = !this.wellsectChecked;
              return d.wellsection_id == sec.wellsection_id;
            });

            this.assemblyList = assembly.sort((a, b) => {
                let fa = Number(a.bha_id),
                    fb = Number(b.bha_id);

                if (fa < fb) {
                    return -1;
                }
                if (fa > fb) {
                    return 1;
                }
                return 0;
            });;
          }
        }
        this.getListOfSavedBha();
      }
    },
    selectBHARun(e, run) {
      if (run.end_time == "1970-01-01T00:00:00.000Z") delete run.end_time;
      this.bhaSelected(run, e.target.checked);
    },
    async getListOfSavedBha() {
      if (this.bha_id && this.planid) {
        let response = await api.WellServices.getSavedBhaRuns({
          bhaId: this.bha_id,
          planId: this.planid,
          bha_for: this.togglValue,
        });
        this.bhaRuns = response.data.rows;
        // console.log("this bha runs : ", this.bhaRuns);
        this.listCount(this.bhaRuns.length);
      }
    },
    selectWellSection(section) {
      this.selected_well_section = section;
      let assembly = this.wellSections.filter((d) => {
        this.wellsectChecked = !this.wellsectChecked;
        return d.wellsection_id == section;
      });

      this.assemblyList = assembly.sort((a, b) => {
                let fa = Number(a.bha_id),
                    fb = Number(b.bha_id);

                if (fa < fb) {
                    return -1;
                }
                if (fa > fb) {
                    return 1;
                }
                return 0;
            });;
      this.assemblyClicked=false;
      this.planid = "";
      this.bha_id = "";
      this.sourceid = "";
      this.changeWellSelectHandler();
    },
    async selectWellAssebly(data) {
      this.assemblyClicked=true;
      this.planid = data.planid;
      this.bha_id = data.bha_id;
      this.sourceid = data.sourceid;
      try {
        await this.getListOfSavedBha();
      } catch (error) {
        console.error(error);
      }

      this.assemblySelected(
        data.planid,
        data.bha_id,
        data.sourceid,
        this.bhaRuns.length,
        data,
        this.assemblyList
      );
    },
    checkHovering(index) {
      if (index == this.hoverIndex) {
        return true;
      } else {
        return false;
      }
    },
    onHover(index) {
      this.hoverIndex = index;
      this.isHovering = true;
    },
    onHoverOut() {
      this.hoverIndex = -1;
      this.isHovering = false;
    },
    dasboardClicked(id) {
      this.$store.dispatch("disp/dashboardClicked", {
        savedDbId: id,
      });
    },
    screenClicked(id) {
      this.$store.dispatch("disp/screenClicked", {
        savedId: id,
      });
    },
    toggleModal(e, detail) {
      this.errcode = "";
      if (e) e.preventDefault();
      if (detail) {
        this.activeFor = detail.activeFor;
        if (detail.savedDbId) {
          this.savedId = detail.savedDbId;
          this.savedName = detail.savedDbName;
        } else {
          this.savedId = detail.savedId;
          this.savedName = detail.savedName;
        }
      } else {
        this.savedId = "";
        this.savedName = "";
      }
      document.getElementById("sidebar-modal").classList.toggle("hidden");
    },
    async onDelete() {
      let response = await api.DashBoardServices.deleteSavedBha({
        bha_id: this.toBeDelete.bha_id,
        bha_for: this.toBeDelete.bha_for,
        plan_id: this.toBeDelete.plan_id,
        sourceid: this.toBeDelete.sourceid,
        name: this.toBeDelete.name,
      });
      this.toggleModalDelete();
      this.getListOfSavedBha();
      this.toBeDelete = "";
      if (response.status == 200) {
        this.$toast.error("Deleted Successfully", {
          position: "top",
          duration: "2000",
        });
      } else {
        if (response.response) {
          this.$toast.error(response.response.message, {
            position: "top",
            duration: "2000",
          });
        }
      }
    },
    async onClear() {
      this.$store.dispatch("disp/clearSelectedDisplay", {
        savedDbId: this.savedId,
      });
      this.activeIndexDB = -1;
      this.toggleModal();
    },
    async onRename() {
      if (!this.savedName) {
        this.$toast.error("Please enter a valid name", {
          position: "top",
          duration: "2000",
        });
        this.errcode = "Please enter a valid name";
        return {};
      }

      if (this.savedName.length > 14) {
        this.$toast.error("Name must be less than 15 characters", {
          position: "top",
          duration: "2000",
        });
        this.errcode = "Name must be less than 15 characters";
        return {};
      }

      let nameExists = await api.DashBoardServices.getNameExists({
        name: this.savedName,
      });

      if (nameExists && nameExists.data) {
        this.errcode = "NAME ALREADY EXISTS";
        this.$toast.error("NAME ALREADY EXISTS", {
          position: "top",
          duration: "2000",
        });
        return {};
      }

      let response = await api.DashBoardServices.renameSavedContent({
        id: this.savedId,
        re_name: this.savedName,
      });

      //
      if (this.savedId && this.savedName) {
        this.$store.dispatch("disp/changeDisplayName", {
          savedId: this.savedId,
          savedName: this.savedName,
        });
      } else {
        this.$store.dispatch("disp/updateCall", {});
      }
      this.toggleModal();
      if (response.status == 200) {
        this.$toast.success("Renamed Successfully", {
          position: "top",
          duration: "2000",
        });
      } else {
        if (response.response) {
          this.$toast.error(response.response.message, {
            position: "top",
            duration: "2000",
          });
        }
      }
    },
    startDrag(ev, props) {
      const rect = ev.target.getBoundingClientRect();
      let offsetX = ev.clientX - rect.x;
      let offsetY = ev.clientY - rect.y;

      ev.dataTransfer.dropEffect = "move";
      ev.dataTransfer.effectAllowed = "move";

      let index = this.displayTags.indexOf(props.title);
      let tagName = this.tags[this.displayTags.indexOf(props.title)];
      let units = this.units[this.displayTags.indexOf(props.title)];

      let elProps = {
        name: props.name,
        offsetY: offsetY,
        offsetX: offsetX,
        color: props.color,
        height: props.height,
        width: props.width,
        title: tagName,
        fullName: props.title,
        units: units,
        logId: this.logId,
        state: this.wellboreState,
        borderDisplay: props.borderDisplay,
      };
      let propSring = JSON.stringify(elProps);

      ev.dataTransfer.setData("elProps", propSring);
    },
    saveDragedIndex(ev, i) {
      this.activeIndexDB = i;
    },
    saveSrnDragedIndex(ev, i) {
      this.activeIndexSrn = i;
    },
    saveBgDragedIndex(ev, i) {
      this.activeIndexBg = i;
    },
    startDragSaved(ev, props) {
      const rect = ev.target.getBoundingClientRect();
      let offsetX = ev.clientX - rect.x;
      let offsetY = ev.clientY - rect.y;

      ev.dataTransfer.dropEffect = "move";
      ev.dataTransfer.effectAllowed = "move";

      // let index = this.displayTags.indexOf(props.title);
      // let tagName = this.tags[this.displayTags.indexOf(props.title)];
      // let units = this.units[this.displayTags.indexOf(props.title)];

      let elProps = {
        ...props,
        offsetY: offsetY,
        offsetX: offsetX,
        isSaved: true,
      };

      let propSring = JSON.stringify(elProps);
      //

      ev.dataTransfer.setData("elProps", propSring);
    },
    toggleDragHandler() {
      let displayId = this.$store.state.disp.selectedDisplay;
      this.$store.dispatch("disp/toggleDragHandler", {
        displayId: displayId,
      });
      this.$store.dispatch("rect/toggleDragHandler", {
        value: !this.isDraggable,
        displayId,
      });
    },
    toggleResizeHandler() {
      let displayId = this.$store.state.disp.selectedDisplay;
      this.$store.dispatch("rect/toggleResizeHandler", {
        displayId: displayId,
      });
    },
    toggleCollapseShow: function (classes) {
      // this.collapseShow = classes;
      if (!this.$store.state.uis.leftSideBarOpen) {
        this.toggleSidebar = "0";
      }
      if (!this.$store.state.rect.leftSideDbBarOpen) {
        this.toggleSidebar = "0";
      }
    },
    toggleTabs: function (tabNumber) {
      this.openTab = tabNumber;
    },
    toggleServices: function (tabService) {
      this.openService = tabService;
    },
    toggleLeftSideBar: function () {
      if (!this.$store.state.uis.leftSideBarOpen) {
        this.toggleSidebar = "0";
      } else {
        // this.toggleCollapseShow('bg-white m-2 py-3 px-6')
      }
      this.$store.dispatch("uis/toggleLeftSideBar");
      // this.$store.dispatch("disp/toggleLeftSideBar");
    },
    toggleLeftSideDbBar: function () {
      if (!this.$store.state.rect.leftSideDbBarOpen) {
        this.toggleSidebar = "0";
      } else {
        // this.toggleCollapseShow('bg-white m-2 py-3 px-6')
      }
      this.$store.dispatch("rect/toggleLeftSideDbBar");
      this.$store.dispatch("disp/toggleLeftSideDbBar");
    },
    warning: function () {
      if (this.value > 50) {
        return {
          color: "#e74c3c",
          animation: "anim .3s ease-in 1 alternate",
          orientation: "vertical",
          direction: "rtl",
        };
      }
    },
    dark() {
      document.querySelector("nav").classList.add("dark-mode");
      document.querySelector("div").classList.add("dark-mode");
      document.querySelector("button").classList.add("dark-mode");
      document.querySelector("a").classList.add("text-red-600");
      this.darkMode = true;
      this.$emit("dark");
    },

    light() {
      document.querySelector("nav").classList.remove("dark-mode");
      document.querySelector("div").classList.remove("dark-mode");
      document.querySelector("button").classList.remove("dark-mode");
      document.querySelector("a").classList.remove("text-red-600");
      this.darkMode = false;
      this.$emit("light");
    },
    toggle_height_handler() {
      this.isExpandWellSection = !this.isExpandWellSection;
    },
    toggle_height_handler_assembly() {
      this.isExpandAssemblySection = !this.isExpandAssemblySection;
    },
    toggle_height_handler_bha() {
      this.isExpandBhaSection = !this.isExpandBhaSection;
    },
    onchange: function () {},
    async toggleTdCheckbox() {
      this.tdcheckbox = !this.tdcheckbox;
      if (this.tdcheckbox == true) {
        this.torHklToggle("HOOKLOAD", this.planid, this.bha_id, this.sourceid);
        this.togglValue = "HOOKLOAD";
      } else {
        this.torHklToggle("TORQUE", this.planid, this.bha_id, this.sourceid);
        this.togglValue = "TORQUE";
      }
      this.$emit("setCheckboxVal", this.tdcheckbox, this.togglValue);
      await this.getListOfSavedBha();
    },
    async toggleRtVsPlanCheckbox() {
      this.rtVsPlan = !this.rtVsPlan;
      if (this.rtVsPlan == true) {
        this.rtVsPlanToggle(
          "RT",
          this.planid,
          this.bha_id,
          this.sourceid,
          this.planvsreal
        );
        this.toggleRtValue = "RT";
      } else {
        this.rtVsPlanToggle(
          "PLAN",
          this.planid,
          this.bha_id,
          this.sourceid,
          this.planvsreal
        );
        this.toggleRtValue = "PLAN";
      }
      // this.$emit('setCheckboxVal', this.rtVsPlan, this.toggleRtValue);
      await this.getListOfSavedBha();
    },
    async zoom_toggle() {
      this.zoom_checked = !this.zoom_checked;
      this.zoomOnOff();
    },
    rtCheckbox(e) {
      this.rtToggle(
        this.toggleValue,
        this.planid,
        this.bha_id,
        this.sourceid,
        e.target.checked
      );
    },
  },
  components: {
    // ChevronRightIcon,
    // ChevronLeftIcon,
  },
  watch: {
    hklOrTor(newValue) {
      this.tdcheckbox = newValue;
      if (this.tdcheckbox == true) {
        // this.torHklToggle('HOOKLOAD', this.planid, this.bha_id, this.sourceid);
        this.togglValue = "HOOKLOAD";
      } else {
        // this.torHklToggle('TORQUE', this.planid, this.bha_id, this.sourceid);
        this.togglValue = "TORQUE";
      }
    },
    isZoomFeature(newValue) {
      this.zoom_checked = newValue;
    },
    async isSaved(newVal) {
      await this.getListOfSavedBha();
    },
    savedList(newValue, oldValue) {
      this.savedData = newValue;
      this.dashboards = [];
      this.screens = [];
      this.backgrounds = [];
      if (this.savedData)
        for (let data of this.savedData.data) {
          if (data.dbtype == "dashboard") {
            this.dashboards.push(data);
          }
          if (data.dbtype == "screen") {
            this.screens.push(data);
          }
          if (data.dbtype == "background") {
            this.backgrounds.push(data);
          }
        }
    },
    async toggleSidebarProp(newValue) {
      if (newValue == -16) this.getWellSections();
    },
    async sideBarForProp(newValue, old) {
      this.search = "";
      if (newValue == "TD")
        if (this.$store.state.uis.leftSideBarOpenForTD) {
          let tqWells = await api.WellServices.getTorqueAndDragWellInfo();
        }
      // this.activeIndexDB = -1;
    },
    wellId(newValue) {
      this.selectedWellId = newValue;

      this.toggleValue = "";
      this.planid = "";
      this.bha_id = "";
      this.sourceid = "";
    },
    async wellboreId(newValue) {
      this.selectedWellBoreId = newValue;
      this.wellSections = [];
      if (this.selectedWellId && this.selectedWellBoreId) {
        await this.getWellSections();
      }
      this.toggleValue = "";
      this.planid = "";
      this.bha_id = "";
      this.sourceid = "";
    },
  },
};
</script>
<!-- src="../../assets/styles/propstyle.css" -->
<style scoped  >
.close-sidebar-16rem {
  left: -16rem;
  transition: left 600ms ease-out;
}

.mySlider {
  transform: rotate(270deg);
}

/* .bg_gray {
  background-color: #141414;
} */

.z_index_20 {
  z-index: 99999;
  height: 100vh;
}

.mx_height_side {
  height: 100%;
}

.text-gray-40 {
  color: #ffffff;
}

.dropdown_hover {
  background: #29292f;
  margin: 10px;
  border-radius: 5px;
}

/* .dropdown_hover:hover {
  background-color: #414141 !important;
} */
.dropdown_hover:hover {
  background-color: #5aba81 !important;
  color: #ffffff !important;
}

.menu_label {
  font: normal normal normal 12px Poppins;
  color: #e4e4e4;
}

.menu_label_option {
  font: normal normal normal 11px Poppins;
  text-align: left;
  color: #e4e4e460;
  padding-left: 1.5rem;
}

.menu_label_option:hover {
  color: #e4e4e4;
}

.mt_50 {
  margin-top: 30px;
}

/* .hamburger_icon {
  position: absolute;
  left: 7px;
  top: 56px;
  z-index: 60;
} */

.form-group {
  display: block;
  margin-bottom: 0;
  margin-top: 0;
}

.form-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.form-group label {
  position: relative;
  cursor: pointer;
  font-size: 10px;
}

/* .form-group label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid white;

  padding: 9px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 15px;
  border-radius: 50%;
} */

.form-group input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 3px;
  left: 7px;
  width: 6px;
  height: 11px;
  border: solid white;
  border-width: 0 1px 1px 0;
  transform: rotate(45deg);
}

.form-group-checkbox {
  margin-top: 0;
  margin-bottom: 0;
}

/* ::-webkit-scrollbar {
  width: 2px !important;
  display: block !important;
} */

/* Track */
/* ::-webkit-scrollbar-track {
  background: #3d3d3d !important;
} */

/* Handle */
/* ::-webkit-scrollbar-thumb {
  background: #5aba81 !important;
} */

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
  background: #5aba81 !important;
} */

/* small checkbox */
/* .form-group-checkbox {
  display: block;
  margin-bottom: 5px;
  margin-top: 5px;
}

.form-group-checkbox .checkbox {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.form-group-checkbox .check-label {
  position: relative;
  cursor: pointer;
}

.form-group-checkbox .check-label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid white;
  padding: 7px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 50%;
  margin-right: 0px !important;
}

.form-group-checkbox .checkbox:checked + .check-label:after {
  content: "";
  display: block;
  position: absolute;
  top: 5px;
  left: 6px;
  width: 4px;
  height: 8px;
  border: solid white;
  border-width: 0 1px 1px 0;
  transform: rotate(45deg);
} */
.search {
  padding: 10px;
  height: 10px;
  /* position: absolute; */
  left: 5px;
  width: 90%;
  margin: 0 auto;
  display: flex;
}

.left_bg {
  background: #eeeeee;
  /* #cdcdcddb; */
  width: 20%;
  color: #000000 !important;
}

.drop_btn_light {
  /* background: #bdbdbd63; */
  /* #ffffff; */
  /* color: #000000 !important; */
  font-size: 10px;
  padding: 2px 10px;
  font-weight: 100;
  letter-spacing: 0.5px;
  margin: 0;
}

.dark-mode {
  background: #1b1a1f;
  color: white !important;
  transition-delay: 150ms;
  width: 20%;
}

/* .left_bg{
  background: white;
   color: black !important;
} */
.drop_btn {
  margin: 0;
  font-size: 8px;
  padding: 2px 10px;
  font-weight: 100;
  letter-spacing: 0.5px;
}

.td_btn {
  margin: 0;
  font-size: calc(0.2vw + 0.2vh + 0.65vmin);
  padding: 4px 10px;
  margin: 6px auto;
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  /* letter-spacing: 0.5px; */
}

.td_btn_dark {
  color: var(--colorBoxHeadingText);
  font-weight: 500;
  background: var(--nav1BgClr);
}

.select_opt {
  /* background: #28282f; */
  padding: 5px 10px;
  margin-bottom: 3px;
  font-size: 8px;
}

.td_toggle-switch {
  margin: 0;
  display: inline-block;
  width: 11px;
  height: 6px;
  -webkit-transform: scale(2);
  transform: scale(2);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  float: right;
  -ms-flex-item-align: end;
  align-self: end;
  -ms-flex-line-pack: end;
  align-content: end;
  align-self: end;
  align-items: center;
  align-self: center;
  margin-left: 10px;
}

.td_justify_end {
  display: flex;
  justify-content: end;
}

.left_mnemo {
  /* margin-top: 2rem; */
  /* border-bottom: 1px #0e1016 solid; */
  scrollbar-color: yellow blue;
  scrollbar-color: #87ceeb #ff5621;
  scrollbar-color: auto;

  /* -webkit-box-shadow: 0px 0px 0px #0e1016;
    -moz-box-shadow: 0px 0px 0px #0e1016;
    box-shadow: 0px 0px 10px #0e1016; */
}

.left_lists_ {
  /* height: 66vh; */
  /* overflow-y: scroll; */
  overflow-x: hidden;
}

/* .bg_green {
} */
.general_checks {
  display: flex;
  justify-content: space-evenly;
  margin: 15px 0;
}

#dragPosition {
  border: 1px solid gray;
}

#resizePosition {
  border: 1px solid gray;
}

/* input {
  outline: 0 !important;
  -webkit-appearance: none;
} */

input[type="checkbox"]:focus {
  outline: 0;
}

.alert_message {
  font-size: 6px;
  text-align: center;
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;
  height: 100%;
}

.label_heading {
  font-weight: 700;
  /* color: #ffffff !important; */
}

/* .label_heading:hover {
  color: #5aba81 !important;
} */
.menu_btns {
  position: absolute;
  left: 32px;
  top: 50px;
  z-index: 60;
}

.left_close_btn {
  padding: 0 1px;
  display: flex;
  height: 100%;
  align-self: center;
  width: 9px;
}

.btns_modal_p {
  display: flex;
  justify-content: space-between;
}

.bg_clear {
  background-color: orange;
}

.modal_width_sidebar {
  width: 32%;
}

.left_z_index {
  /* z-index: 999999; */
  z-index: 9999;
  width: 100%;
  /* height: 100%; */
}

/* left side bar tooltip with first child bottom */

/*  */

.tooltip__tip {
  position: relative;
}

.tooltip__tip::after {
  background-color: #000000;
  border-radius: 10px;
  display: none;
  padding: 10px 15px;
  position: absolute;
  text-align: center;
  z-index: 999;
}

.tooltip__tip::before {
  background-color: #000000;
  content: " ";
  display: none;
  position: absolute;
  width: 7px;
  height: 7px;
  z-index: 999;
}

.tooltip__tip:hover::after {
  display: block;
  color: white;
}

.tooltip__tip:hover::before {
  display: block;
}

.tooltip__tip.top_::after {
  content: attr(data-tip);
  top: 10px;
  left: 50%;
  width: 100%;
  font-size: 7px;
  padding: 3px;
  transform: translate(-50%, calc(-100% - 10px));
}

.tooltip__tip.top_::before {
  top: 9px;
  left: 50%;
  transform: translate(-50%, calc(-100% - 5px)) rotate(45deg);
}

.parent {
  background: unset;
  width: 92%;
  height: 100%;
  margin: 0 auto;
}

.selectedBtn {
  position: relative;
}

.selectedBtn_tooltip {
  width: 100%;
  /* width: 100%;
      overflow: hidden;
      display: inline-block;
      text-overflow: ellipsis;
      white-space: nowrap; */
}

.td_btn {
  margin: 0;
  font-size: calc(0.2vw + 0.2vh + 0.65vmin);
  padding: 4px 10px;
  /* margin: 5px auto; */
  display: flex;
  justify-content: space-between;
  color: var(--colorBoxHeadingText);
  height: 30px;
  align-items: center;

  font-weight: 600;
  /* letter-spacing: 0.5px; */
}

.switch_text_top {
  font-size: 8px;
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 10px;
  /* top: 15px; */
  -webkit-transform: scale(2);
  transform: scale(2);
}

.checkbox {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch-btn {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 30px;
  background-color: #414141;
  cursor: pointer;
  transition: 0.4s;
  -webkit-transition: 0.4s;
}

/* .switch-btn::after {
  position: absolute;
  content: "HKL";

} */
.switch-btn::before {
  position: absolute;
  content: "";
  height: 6px;
  width: 6px;
  left: 0px;
  bottom: 0px;
  border-radius: inherit;
  background-color: #fff;
  -webkit-box-shadow: 0 0 4px rgb(0 0 0 / 15%);
  box-shadow: 0 0 4px rgb(0 0 0 / 15%);
  -webkit-transition: 0.4s ease all,
    left 0.4s cubic-bezier(0.18, 0.89, 0.35, 1.15);
  transition: 0.4s ease all, left 0.4s cubic-bezier(0.18, 0.89, 0.35, 1.15);
}

.switch-btn:active:before {
  width: 40px;
}

.checkbox:checked + .switch-btn {
  background-color: #414141;
}

.checkbox:checked + .switch-btn::before {
  left: calc(100%);
  transform: translateX(-100%);
}

.side_pan_width {
  width: 13%;
  background: var(--TandDCorrelationLeftBarBg);
}

.side_pan_no_width {
  width: 0 !important;
}

.list_td {
  font-size: 10px;
  font-weight: normal;
}

.tooltip__tip {
  position: relative;
}

.tooltip__tip::after {
  background-color: #000000;
  border-radius: 10px;
  display: none;
  padding: 10px 15px;
  position: absolute;
  text-align: center;
  z-index: 999;
}

.tooltip__tip::before {
  background-color: #000000;
  content: " ";
  display: none;
  position: absolute;
  width: 7px;
  height: 7px;
  z-index: 999;
}

.tooltip__tip:hover::after {
  display: block;
}

.tooltip__tip:hover::before {
  display: block;
}

.tooltip__tip.top::after {
  content: attr(data-tip);
  top: 2px;
  left: 50%;
  width: 100%;
  font-size: 7px;
  padding: 3px;
  transform: translate(-50%, calc(-100% - 10px));
}

.tooltip__tip.top::before {
  top: 0;
  left: 50%;
  transform: translate(-50%, calc(-100% - 5px)) rotate(45deg);
}

.parent {
  background: unset;
  width: 92%;
  height: 100%;
  margin: 0 auto;
}

.selectedBtn {
  position: relative;
}

.checkbox {
  opacity: 0;
  position: absolute;
}

.td_checkbox {
  background-color: var(--toggleSliderBg);
  width: 30px;
  height: 14px;
  border-radius: 50px;
  position: relative;
  padding: 5px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 !important;
}

.checkbox:checked + .td_checkbox_zoom .ball {
  transform: translateX(13px);
  /* background: #5aba81; */
}

.checkbox:checked + .td_checkbox_zoom {
  background: var(--activeTextColor);
}

.checkbox:checked + .ball {
  background-color: red;
}

.td_checkbox_zoom {
  background-color: var(--toggleSliderBg);
  width: 30px;
  height: 14px;
  border-radius: 50px;
  position: relative;
  padding: 5px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.td_checkbox_zoom .ball {
  background-color: var(--sidebarbg);
  width: 12px;
  height: 12px;
  position: absolute;
  left: 3px;
  top: 1px;
  border-radius: 50%;
  transition: transform 0.2s linear;
}

.theme_icon {
  font-size: 12px !important;
}

.td_checkbox .ball {
  background-color: var(--sidebarbg);
  width: 12px;
  height: 12px;
  position: absolute;
  left: 3px;
  top: 1px;
  border-radius: 50%;
  transition: transform 0.2s linear;
}

.checkbox:checked + .td_checkbox .ball {
  transform: translateX(13px);
  /* background: #5aba81; */
}

.checkbox:checked + .td_checkbox {
  background: var(--activeTextColor);
}

.td_toggle {
  width: 100%;
  height: 100%;
  /* border-right: 0.5px solid #1b1a1f; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 10px;
  color: var(--colorBoxHeadingText);
  font-size: 11px;
  /* margin: 10px 10px; */
}

.active_toggle_text {
  color: var(--activeTextColor);
}

.arrow_s {
  margin: 0 3px;
}

.drop_backg {
  background: var(--navBar2Bg);
}

[type="radio"]:checked {
  background-image: url(../../assets/img/radio_button_selected_custom.svg) !important;
}

.sidebar_heading {
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  color: var(--headingColor);
  padding: 0px;
  letter-spacing: normal;
  height: 32px;
  align-items: center;
  justify-content: center;
}

.bg_right_ {
  margin: 0px 0px 3px 0px !important;
  /* height: 25px; */
  background: var(--sidebarListBgColor) !important;
  border-radius: 0px;
  /* padding: 0px 7px !important; */
}

.fa-angle-down {
  color: #727272;
}

.fa-angle-up {
  color: #727272;
}

.td_text-xs {
  font-size: calc(0.2vw + 0.2vh + 0.65vmin);
  font-weight: 600;
}
.delete_bha_popup{
  z-index: 9999999;
}
.del_saved_bha{
  color: var(--textColor);
}
</style>
